html, 
body {
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;
}

.container {
  padding: 10px;
}

.navbar {
  background-color: #444;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-bottom: 40px;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar ul li a {
  padding: 10px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.navbar ul li a.active {
  color: chartreuse;
}

.anim {
  width: 100vw;
  height: 100vh;
  background-color: black;
}



